import gql from 'graphql-tag';

export const condominiumPersonContextParams = gql`
  query condominiumPersonContextParams(
    $queryInput: CondominiumPersonContextQueryInput
  ) {
    condominiumPersonContextParams(queryInput: $queryInput) {
      id
      personId
      treeNodeId
      personContextParams {
        personContextId
        name
        value
        id
      }
    }
  }
`;

export const updateCondominiumPersonContextParams = gql`
  mutation updateCondominiumPersonContextParams(
    $data: [CondominiumPersonContextParamsInput]
  ) {
    updateCondominiumPersonContextParams(data: $data) {
      id
      name
      personContextId
      value
      creationDate
      lastUpdate
    }
  }
`;

export const fingerSnapOperatorHelper = gql`
  mutation fingerSnapOperatorHelper($data: FingerSnapHelperData) {
    fingerSnapOperatorHelper(data: $data)
  }
`;
