import { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import * as UI from '@kiper/ui';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { preRegisteredUsersCount } from '@kiper/monitoring-graphql/pre_register/query';
import useCurrentLoggedContext from '../../hooks/useCurrentLoggedContext';
import PageHeader from '../PageHeader';
import TabHeaderPage from '../TabHeaderPage';
import { sendAmplitudeData } from '../../services/amplitude';
import * as S from './styles';

export const tabIds = Object.freeze({
  USERS_LIST: 'users-list',
  BLOCKED: 'blocked-users-list',
  PENDING_APPROVAL: 'pending-approval',
});

const UsersPageActions = ({
  route,
  tabId,
  t,
  title,
  children,
  headerAction: HeaderAction,
}) => {
  const history = useHistory();

  const { loggedContext } = useCurrentLoggedContext();
  const [activeTab, setActiveTab] = useState(tabId);

  const { data, loading } = useQuery(preRegisteredUsersCount);

  const tabs = [
    {
      title: t(`tabs.${tabIds.USERS_LIST}`),
      tabId: tabIds.USERS_LIST,
      showTab: true,
      link: '/users',
    },
    {
      title: t(`tabs.${tabIds.PENDING_APPROVAL}`),
      tabId: tabIds.PENDING_APPROVAL,
      showTab: true,
      link: '/users/pending-approval',
      icon:
        !loading && data?.preRegisteredUsersCount?.usersQuantity ? (
          <S.CounterIcon>
            {data.preRegisteredUsersCount.usersQuantity}
          </S.CounterIcon>
        ) : (
          <></>
        ),
    },
    {
      title: t(`tabs.${tabIds.BLOCKED}`),
      tabId: tabIds.BLOCKED,
      showTab: true,
      link: '/users/blocked',
    },
  ];

  const handleChangeTab = useCallback(
    (tab, link) => {
      history.push(link);
      setActiveTab(tab);
      sendAmplitudeData(`change to tab -> ${tab}`, {
        user: loggedContext?.email,
        partner: loggedContext?.partner?.name,
      });
    },
    [activeTab],
  );

  return (
    <>
      <PageHeader
        t={t}
        breadcrumb={route.breadcrumb}
        title={title}
        headerButton={HeaderAction}
        tabHeaderPage={
          tabs.length && (
            <TabHeaderPage
              tabs={tabs}
              activeTab={activeTab}
              onChangeTab={handleChangeTab}
            />
          )
        }
      />

      <UI.TabContent activeTab={activeTab}>
        <UI.TabPane tabId={tabId}>{children}</UI.TabPane>
      </UI.TabContent>
    </>
  );
};

export default UsersPageActions;

UsersPageActions.propTypes = {
  children: propTypes.element.isRequired,
  route: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
  headerAction: propTypes.element,
  tabId: propTypes.string.isRequired,
};

UsersPageActions.defaultProps = {
  headerAction: null,
};
