import { useState } from 'react';
import propTypes from 'prop-types';
import fingerSnapIcon from './finger-snap-icon.svg';

const styles = {
  Wrapper: {
    height: 'fit-content',
    width: 'fit-content',
    position: 'absolute',
    bottom: 4,
    left: 4,
    display: 'flex',
    alignItems: 'flex-end',
    gap: 8,
    zIndex: 99999,
  },
  Button: {
    border: 'none',
    height: 'fit-content',
    width: 'auto',
    padding: '8px',
    borderRadius: '50%',
    background: '#29AD71',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '-6px 6px 14px -6px rgba(0,0,0,0.7)',
  },
  Popup: {
    border: 'none',
    height: 'fit-content',
    width: 'auto',
    padding: '8px',
    borderRadius: '8px',
    background: '#29AD71',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    gap: 8,
    boxShadow: '-6px 6px 14px -6px rgba(0,0,0,0.6)',
  },
  TextArea: {
    border: 'none',
    height: 'fit-content',
    width: 'auto',
    padding: '8px',
    borderRadius: '8px',
  },
  SubmitButton: {
    border: 'none',
    height: 'fit-content',
    width: 'auto',
    padding: 16,
    borderRadius: 8,
    background: '#29AD71',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    color: 'white',
    fontSize: 24,
    lineHeight: 1,
    boxShadow: '-6px 6px 14px -6px rgba(0,0,0,0.7)',
  },
};
const FingerSnapHelper = ({ onSubmit }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpen = () => setOpenPopup(prevState => !prevState);
  const handleSubmit = e => {
    e.preventDefault();
    setOpenPopup(false);
    onSubmit(e);
  };

  return (
    <div style={styles.Wrapper}>
      <button type="button" style={styles.Button} onClick={handleOpen}>
        <img alt="icon" src={fingerSnapIcon} height={40} width={40} />
      </button>
      {openPopup && (
        <button
          type="button"
          style={styles.SubmitButton}
          onClick={handleSubmit}
        >
          Solicitar ajuda
        </button>
      )}
    </div>
  );
};

FingerSnapHelper.propTypes = {
  onSubmit: propTypes.func.isRequired,
};

export default FingerSnapHelper;
